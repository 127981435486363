import React, { useState } from 'react'
import { useSession } from '@vtfk/react-msal'
import { Heading2, Heading3, Skeleton, StatisticsGroup, StatisticsCard, StatisticsProgressBar } from '@vtfk/components'

import useEffectOnce from '../../components/useEffectOnce'

import { Layout } from '../../layout'
import { APP } from '../../config'

import './styles.scss'

export const Statistics = () => {
  const [schoolStats, setSchoolStats] = useState([])
  const [loading, setLoading] = useState(true)

  const { apiGet } = useSession()

  async function getAPISchoolStats () {
    const schoolStats = await apiGet(APP.API_URL + '/stats/school')
    setSchoolStats(schoolStats)
    setLoading(false)
  }

  function getType (type) {
    if (!schoolStats) return null
    const typeStat = schoolStats.filter(stats => stats.type === type)
    return typeStat.length > 0 ? typeStat[0] : null
  }

  function getSchools (type) {
    const schools = (getType(type) || {}).schools || []
    return schools.length > 0 ? schools.sort((a, b) => a.count < b.count ? 1 : -1) : []
  }

  function getTypeCount (type) {
    return (getType(type) || {}).count?.toString() || '0'
  }

  function getTypeHighscore (type) {
    const schools = getSchools(type)
    return schools.length > 0 ? schools[0].count : 0
  }

  useEffectOnce(() => {
    getAPISchoolStats()
  })

  return (
    <Layout className='statistics'>

      <Heading2 as='h1' className='page-title'>
        Statistikk
      </Heading2>

      <StatisticsGroup className='stats-group'>
        <StatisticsCard title='varselbrev' size='large'>
          {
            loading
              ? <Skeleton randomWidth={[20, 80]} />
              : getTypeCount('varsel')
          }
        </StatisticsCard>
        <StatisticsCard title='dokumenterte elevsamtaler' size='large'>
          {
            loading
              ? <Skeleton randomWidth={[20, 80]} />
              : getTypeCount('samtale')
          }
        </StatisticsCard>
        <StatisticsCard title='notater til elevmappa' size='large'>
          {
            loading
              ? <Skeleton randomWidth={[20, 80]} />
              : getTypeCount('notat')
          }
        </StatisticsCard>
      </StatisticsGroup>

      <div className='stats-collapse'>
        <Heading3 as='h2' className='stats-collapse-title'>
          Varsler fordelt pr. skole
        </Heading3>

        <StatisticsGroup type='progress'>
          {
            loading
              ? Array(3).fill().map((i) => <tr key={i}><td><Skeleton /></td></tr>)
              : getSchools('varsel').map((item, index) => <StatisticsProgressBar key={index} name={item.name} value={item.count} maxValue={getTypeHighscore('varsel')} />)
          }
        </StatisticsGroup>
      </div>

      <div className='stats-collapse'>
        <Heading3 as='h2' className='stats-collapse-title'>
          Samtaler fordelt pr. skole
        </Heading3>

        <StatisticsGroup type='progress'>
          {
            loading
              ? Array(3).fill().map((i) => <tr key={i}><td><Skeleton /></td></tr>)
              : getSchools('samtale').map((item, index) => <StatisticsProgressBar key={index} name={item.name} value={item.count} maxValue={getTypeHighscore('samtale')} />)
          }
        </StatisticsGroup>
      </div>

      <div className='stats-collapse'>
        <Heading3 as='h2' className='stats-collapse-title'>
          Notater fordelt pr. skole
        </Heading3>

        <StatisticsGroup type='progress'>
          {
            loading
              ? Array(3).fill().map((i) => <tr key={i}><td><Skeleton /></td></tr>)
              : getSchools('notat').map((item, index) => <StatisticsProgressBar key={index} name={item.name} value={item.count} maxValue={getTypeHighscore('notat')} />)
          }
        </StatisticsGroup>
      </div>

    </Layout>
  )
}
