import React from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'

import useEffectOnce from '../useEffectOnce'

import { DOCUMENTS } from '../../data/documents'

import { Modal, ModalBody, ModalSideActions, TextField, Link } from '@vtfk/components'

import StudentCard from '../StudentCard'
import ErrorFallback from '../../components/ErrorFallback'

import './styles.scss'

export function PreviewDocumentModal ({ previewDoc, ...props }) {
  const prettyPrintDate = (date, includeTime) => {
    if (includeTime) return new Date(date).toLocaleDateString('nb-NO', { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' })
    else return new Date(date).toLocaleDateString('nb-NO', { day: '2-digit', month: 'long', year: 'numeric' })
  }

  const generateStatus = () => {
    return previewDoc.status.map(status => {
      return `${prettyPrintDate(status.timestamp, true)} - ${DOCUMENTS.documentStatuses.find(docStatus => docStatus.id === status.status).short.nb}`
    }).join('\n')
  }

  // varsel
  const documentPeriod = previewDoc.content.period && DOCUMENTS.periods.find(type => type.id === previewDoc.content.period.id)?.value.nb
  const documentFag = previewDoc.content.classes && previewDoc.content.classes.map(item => item.nb)?.join('\n')

  // samtale
  const documentSamtale = previewDoc.type === 'samtale' && DOCUMENTS.conversationStatuses.find(type => type.id === previewDoc.variant)?.value.nb

  // default
  // const documentYear = previewDoc && previewDoc.content.year
  const documentTeacher = previewDoc.teacher && previewDoc.teacher.name
  const documentDate = previewDoc && prettyPrintDate(previewDoc.created.timestamp)
  const documentStatus = previewDoc.status && generateStatus()

  useEffectOnce(() => {
    // Close modal on escape
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') props.onDismiss()
    }

    document.addEventListener('keyup', handleKeyPress)
    return () => document.removeEventListener('keyup', handleKeyPress)
  })

  return (
    <Sentry.ErrorBoundary
      fallback={ErrorFallback}
      onReset={props.onDismiss}
    >

      <Modal
        {...props}
        className='preview-document-modal'
        onDismiss={props.onDismiss}
        onFinished={props.onFinished}
      >
        <ModalBody>
          <StudentCard student={{ ...previewDoc.student, schoolName: previewDoc.school.name }} hideBirthdate />

          <div className='form'>

            {
              /* --------------------
                Periode
              -------------------- */
              previewDoc.content.period &&
                <TextField
                  className='textfield-margin'
                  disabled
                  noBorder
                  placeholder='Periode'
                  value={documentPeriod || 'Auda 🤭'}
                />
            }

            {
              /* --------------------
                Fag
              -------------------- */
              previewDoc.variant && previewDoc.variant === 'fag' &&
                <TextField
                  className='textfield-margin'
                  disabled
                  noBorder
                  placeholder={`${documentFag.split('\n').length > 1 ? 'Valgte' : 'Valgt'} fag for varselet`}
                  rows={documentFag.split('\n')?.length || 1}
                  value={documentFag || 'Auda 🤭'}
                />
            }

            {
              /* --------------------
                Samtale
              -------------------- */
              previewDoc.type && previewDoc.type === 'samtale' &&
                <TextField
                  className='textfield-margin'
                  disabled
                  noBorder
                  placeholder='Er det gjennomført en elevsamtale?'
                  value={documentSamtale || 'Auda 🤭'}
                />
            }

            {
              /* --------------------
                Teacher
              -------------------- */
              previewDoc.teacher &&
                <TextField
                  className='textfield-margin'
                  disabled
                  noBorder
                  placeholder='Sendt av'
                  value={documentTeacher || 'Auda 🤭'}
                />
            }

            {
              /* --------------------
                Generated
              -------------------- */
              previewDoc &&
                <TextField
                  className='textfield-margin'
                  disabled
                  noBorder
                  placeholder='Dato'
                  value={documentDate || 'Auda 🤭'}
                />
            }

            {
              /* --------------------
                Status
              -------------------- */
              previewDoc.status &&
                <TextField
                  className='textfield-margin'
                  disabled
                  noBorder
                  placeholder='Status'
                  rows={documentStatus.split('\n').length || 1}
                  value={documentStatus || 'Auda 🤭'}
                />
            }
            <div className='preview-alert'>
              Innholdet i dokumentet/grunnlag finner du i elevens elevmappe i Public 360.
            </div>

          </div>
        </ModalBody>

        <ModalSideActions>
          <div className='action'>
            <Link onClick={() => props.onDismiss()}>Lukk</Link>
          </div>
        </ModalSideActions>
      </Modal>
    </Sentry.ErrorBoundary>
  )
}

PreviewDocumentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onDismiss: PropTypes.func.isRequired,
  onFinished: PropTypes.func.isRequired,
  previewDoc: PropTypes.object.isRequired,
  className: PropTypes.string
}
