import { Skeleton, Heading3, Paragraph, Link } from '@vtfk/components'

export const SchoolCard = ({ school, loading, ...props }) => {
  if (!school) loading = true

  const { shortName, officialName, address, mail, phoneNumber } = school || {}

  return (
    <div className='school-information'>
      <div className='school-shortname'>
        {shortName || 'OF'}
      </div>
      <div className='text-wrapper'>
        <Heading3 className='name'>
          {
            loading
              ? <Skeleton width='240px' />
              : (officialName)
          }
        </Heading3>
        <div className='other'>

          {
            loading ? <Paragraph><Skeleton width='140px' /></Paragraph> : <><Link href={`mailto:${mail}`} tabIndex={-1}>{mail}</Link> / {phoneNumber}</>
          }

          <Paragraph>
            {
              loading ? <Skeleton width='140px' /> : `${address.street}, ${address.zip} ${address.place}`
            }
          </Paragraph>
        </div>
      </div>
    </div>
  )
}
