import React from 'react'
import { Heading2, Heading3, Paragraph, Heading4 } from '@vtfk/components'

import { Layout } from '../../layout'

import './styles.scss'

const QuestionBlock = ({ question, children }) => {
  return (
    <div className='help-question-block'>
      <Heading4 className='help-section-question'>
        {question}
      </Heading4>

      <Paragraph className='help-text-block'>
        {children}
      </Paragraph>
    </div>
  )
}

export const Help = () => {
  return (
    <Layout className='help'>
      <Heading2 as='h1' className='page-title'>
        Hjelpeside
      </Heading2>

      <Heading3 as='h2' className='help-section-title'>
        Introduksjon
      </Heading3>

      <Paragraph className='help-text-block'>
        Nedenfor finner du ofte stilte spørsmål spesifikt for MinElev leder.
      </Paragraph>

      <Heading3 as='h2' className='help-section-title'>
        Ofte stilte spørsmål
      </Heading3>

      <QuestionBlock question='Hva er forskjellen mellom "MinElev" og "MinElev leder"?'>
        <em>MinElev leder</em> er en innsynløsning som lar deg som leder få innsyn i hva som rører seg på skolen.<br />
        <em>MinElev</em> er derimot løsningen lærerne bruker for å produsere dokumenter som skal sendes ut. Tilgang til MinElev styres fra det skoleadministrative systemet (Visma InSchool).
      </QuestionBlock>

      <QuestionBlock question='Hvordan får jeg tilgang til MinElev leder for skolen min?'>
        Tilgang til skoler i MinElev leder tildeles av systemansvarlig i opplæring og folkehelse (OF).
      </QuestionBlock>

      <QuestionBlock question='Har jeg som leder tilgang til elevene på skolen i MinElev- eller Elevmappa-løsningene?'>
        Nei, ikke i utgangspunktet. MinElev er et verktøy for å produsere dokumenter som skal sendes til eleven (og foresatte), mens Elevmappa gir lærerne innsyn i dokumenter som ligger lagret i elevens elevmappe i Public 360.<br /><br />
        Du som leder har tilgang til samme informasjon for skolen din via denne løsningen, samt rollen din i Public 360.
      </QuestionBlock>

      <QuestionBlock question='Hvorfor får jeg ikke opp innholdet i dokumentene som er sendt?'>
        MinElev leder er kun ment for å få et overblikksbilde av situasjonen i klassene. Trenger du mer detaljert informasjon om innholdet i varslene/notatene/elevsamtalene, må du slå opp i elevens elevmappe i Public 360.
      </QuestionBlock>
    </Layout>
  )
}
