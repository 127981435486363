import { useState, useEffect } from 'react'

function UseEffectOnce (callback) {
  const [hasRun, setHasRun] = useState(false)

  useEffect(() => {
    if (callback && !hasRun) {
      callback()
      setHasRun(true)
    }
  }, [hasRun, callback])
}

export default UseEffectOnce
