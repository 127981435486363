export const SCHOOLS = [
  {
    schoolId: '38099',
    organizationNumber: '821227062',
    organizationNumber360: '821227062',
    name: 'Testen vgs',
    fullName: 'Testen videregående skole',
    shortName: 'TESVS',
    officialName: 'Testen videregående skole',
    accessGroup: 'Elev Testen vgs',
    phoneNumber: '35 91 70 00',
    mail: 'testen.vgs@vtfk.no',
    address: {
      street: 'Torggata 18',
      zip: '3717',
      place: 'SKIEN'
    },
    geocoded: {
      lat: '59.94026',
      lon: '10.75393'
    },
    yff: true
  }
]

export const CLASSES = [
  {
    id: '1392',
    groupId: 'TESVS:1STA',
    type: 'basisgruppe',
    name: '1STA',
    description: 'Basisgruppe 1STA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '92505',
    groupId: 'TESVS:1STB',
    type: 'basisgruppe',
    name: '1STB',
    description: 'Basisgruppe 1STB ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '6971',
    groupId: 'TESVS:1STC',
    type: 'basisgruppe',
    name: '1STC',
    description: 'Basisgruppe 1STC ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '43107',
    groupId: 'TESVS:1STD',
    type: 'basisgruppe',
    name: '1STD',
    description: 'Basisgruppe 1STD ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '7038',
    groupId: 'TESVS:1STE',
    type: 'basisgruppe',
    name: '1STE',
    description: 'Basisgruppe 1STE ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '86576',
    groupId: 'TESVS:1TPA',
    type: 'basisgruppe',
    name: '1TPA',
    description: 'Basisgruppe 1TPA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '33425',
    groupId: 'TESVS:1TPB',
    type: 'basisgruppe',
    name: '1TPB',
    description: 'Basisgruppe 1TPB ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '51720',
    groupId: 'TESVS:1ELA',
    type: 'basisgruppe',
    name: '1ELA',
    description: 'Basisgruppe 1ELA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '79680',
    groupId: 'TESVS:1SSA',
    type: 'basisgruppe',
    name: '1SSA',
    description: 'Basisgruppe 1SSA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '9904',
    groupId: 'TESVS:1SSB',
    type: 'basisgruppe',
    name: '1SSB',
    description: 'Basisgruppe 1SSB ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '97176',
    groupId: 'TESVS:1SSC',
    type: 'basisgruppe',
    name: '1SSC',
    description: 'Basisgruppe 1SSC ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '48723',
    groupId: 'TESVS:1IMA',
    type: 'basisgruppe',
    name: '1IMA',
    description: 'Basisgruppe 1IMA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '6900',
    groupId: 'TESVS:1IBA',
    type: 'basisgruppe',
    name: '1IBA',
    description: 'Basisgruppe 1IBA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '12213',
    groupId: 'TESVS:1HOA',
    type: 'basisgruppe',
    name: '1HOA',
    description: 'Basisgruppe 1HOA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '53550',
    groupId: 'TESVS:1HOC',
    type: 'basisgruppe',
    name: '1HOC',
    description: 'Basisgruppe 1HOC ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '51377',
    groupId: 'TESVS:1HOD',
    type: 'basisgruppe',
    name: '1HOD',
    description: 'Basisgruppe 1HOD ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '99091',
    groupId: 'TESVS:2STA',
    type: 'basisgruppe',
    name: '2STA',
    description: 'Basisgruppe 2STA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '20580',
    groupId: 'TESVS:2STB',
    type: 'basisgruppe',
    name: '2STB',
    description: 'Basisgruppe 2STB ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '28509',
    groupId: 'TESVS:2STR',
    type: 'basisgruppe',
    name: '2STR',
    description: 'Basisgruppe 2STR ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '87095',
    groupId: 'TESVS:2IUA',
    type: 'basisgruppe',
    name: '2IUA',
    description: 'Basisgruppe 2IUA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '63018',
    groupId: 'TESVS:2SSA',
    type: 'basisgruppe',
    name: '2SSA',
    description: 'Basisgruppe 2SSA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '68262',
    groupId: 'TESVS:2REA',
    type: 'basisgruppe',
    name: '2REA',
    description: 'Basisgruppe 2REA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '16780',
    groupId: 'TESVS:2MUB',
    type: 'basisgruppe',
    name: '2MUB',
    description: 'Basisgruppe 2MUB ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '44270',
    groupId: 'TESVS:2STL',
    type: 'basisgruppe',
    name: '2STL',
    description: 'Basisgruppe 2STL ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '1456',
    groupId: 'TESVS:2IBA',
    type: 'basisgruppe',
    name: '2IBA',
    description: 'Basisgruppe 2IBA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '49490',
    groupId: 'TESVS:2IKA',
    type: 'basisgruppe',
    name: '2IKA',
    description: 'Basisgruppe 2IKA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '46015',
    groupId: 'TESVS:2RFA',
    type: 'basisgruppe',
    name: '2RFA',
    description: 'Basisgruppe 2RFA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '31490',
    groupId: 'TESVS:2MUA',
    type: 'basisgruppe',
    name: '2MUA',
    description: 'Basisgruppe 2MUA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '92433',
    groupId: 'TESVS:2STD',
    type: 'basisgruppe',
    name: '2STD',
    description: 'Basisgruppe 2STD ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '30634',
    groupId: 'TESVS:2IKB',
    type: 'basisgruppe',
    name: '2IKB',
    description: 'Basisgruppe 2IKB ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '17371',
    groupId: 'TESVS:2HAA',
    type: 'basisgruppe',
    name: '2HAA',
    description: 'Basisgruppe 2HAA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '53491',
    groupId: 'TESVS:2FRA',
    type: 'basisgruppe',
    name: '2FRA',
    description: 'Basisgruppe 2FRA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '92898',
    groupId: 'TESVS:2EEA',
    type: 'basisgruppe',
    name: '2EEA',
    description: 'Basisgruppe 2EEA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '30729',
    groupId: 'TESVS:2STH',
    type: 'basisgruppe',
    name: '2STH',
    description: 'Basisgruppe 2STH ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '25541',
    groupId: 'TESVS:3STA',
    type: 'basisgruppe',
    name: '3STA',
    description: 'Basisgruppe 3STA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '2524',
    groupId: 'TESVS:3STB',
    type: 'basisgruppe',
    name: '3STB',
    description: 'Basisgruppe 3STB ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '83514',
    groupId: 'TESVS:3STC',
    type: 'basisgruppe',
    name: '3STC',
    description: 'Basisgruppe 3STC ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '197',
    groupId: 'TESVS:3STD',
    type: 'basisgruppe',
    name: '3STD',
    description: 'Basisgruppe 3STD ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '54784',
    groupId: 'TESVS:3STI',
    type: 'basisgruppe',
    name: '3STI',
    description: 'Basisgruppe 3STI ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '44337',
    groupId: 'TESVS:3SSSS',
    type: 'basisgruppe',
    name: '3SSSS',
    description: 'Basisgruppe 3SSSS ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  },
  {
    id: '86659',
    groupId: 'TESVS:3HEA',
    type: 'basisgruppe',
    name: '3HEA',
    description: 'Basisgruppe 3HEA ved Testen videregående skole',
    schoolId: '38099',
    schoolName: 'Testen videregående skole',
    validFrom: '2020-08-01',
    validTo: '2021-07-31'
  }
]

export const YFF = [
  {
    name: 'Subjektiv Gitar',
    username: 'sub1208',
    classId: 'TESVS:3HEA',
    level: 'VG3',
    utdanningsprogram: [
      {
        id: 'HS',
        groupId: 'HS_TESVS',
        type: 'utdanningsprogram',
        name: 'Helse- og oppvekstfag',
        description: '',
        schoolId: 'TESVS@38099',
        schoolName: 'Testen videregående skole',
        validFrom: '1971-11-18',
        validTo: '1986-11-26',
        adminPeriod: '1971/1986'
      }
    ],
    maal: [
      {
        programomraade: {
          kode: 'HSAMB3----',
          'url-data': 'https://data.udir.no/kl06/v201906/programomraader/HSAMB3----',
          tittel: {
            nb: 'Ambulansefaget',
            nn: 'Ambulansefaget',
            en: 'Ambulance Service Skills'
          },
          opplaeringssted: [
            'http://psi.udir.no/kl06/opplaeringssted_bedrift'
          ]
        },
        maal: [
          {
            _id: '6015576e442afa643265519d',
            referanseId: '60155736b44bf763f6ebf146',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13776',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13776',
              tittel: {
                nb: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende',
                nn: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende',
                en: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa643265519c',
            referanseId: '60155736b44bf763f6ebf146',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13786',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13786',
              tittel: {
                nb: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak ',
                nn: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak ',
                en: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak '
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa6432655199',
            referanseId: '60155736b44bf763f6ebf146',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13782',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13782',
              tittel: {
                nb: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen',
                nn: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen',
                en: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa643265519a',
            referanseId: '60155736b44bf763f6ebf146',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13778',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13778',
              tittel: {
                nb: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak',
                nn: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak',
                en: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa643265519b',
            referanseId: '60155736b44bf763f6ebf146',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13785',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13785',
              tittel: {
                nb: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak',
                nn: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak',
                en: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa643265519e',
            referanseId: '60155736b44bf763f6ebf146',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13784',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13784',
              tittel: {
                nb: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter ',
                nn: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter ',
                en: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter '
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          }
        ]
      }
    ]
  },
  {
    name: 'Sjokkert Besteforelder',
    username: 'sjo1208',
    classId: 'TESVS:3HEA',
    level: 'VG3',
    utdanningsprogram: [
      {
        id: 'HS',
        groupId: 'HS_TESVS',
        type: 'utdanningsprogram',
        name: 'Helse- og oppvekstfag',
        description: '',
        schoolId: 'TESVS@38099',
        schoolName: 'Testen videregående skole',
        validFrom: '1971-11-18',
        validTo: '1986-11-26',
        adminPeriod: '1971/1986'
      }
    ],
    maal: [
      {
        programomraade: {
          kode: 'HSAMB3----',
          'url-data': 'https://data.udir.no/kl06/v201906/programomraader/HSAMB3----',
          tittel: {
            nb: 'Ambulansefaget',
            nn: 'Ambulansefaget',
            en: 'Ambulance Service Skills'
          },
          opplaeringssted: [
            'http://psi.udir.no/kl06/opplaeringssted_bedrift'
          ]
        },
        maal: [
          {
            _id: '6015576e442afa64326551d0',
            referanseId: '60155736b44bf763f6ebf152',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13782',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13782',
              tittel: {
                nb: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen',
                nn: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen',
                en: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326551ce',
            referanseId: '60155736b44bf763f6ebf152',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13784',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13784',
              tittel: {
                nb: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter ',
                nn: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter ',
                en: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter '
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326551cc',
            referanseId: '60155736b44bf763f6ebf152',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13786',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13786',
              tittel: {
                nb: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak ',
                nn: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak ',
                en: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak '
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326551cd',
            referanseId: '60155736b44bf763f6ebf152',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13785',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13785',
              tittel: {
                nb: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak',
                nn: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak',
                en: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326551cf',
            referanseId: '60155736b44bf763f6ebf152',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13776',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13776',
              tittel: {
                nb: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende',
                nn: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende',
                en: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326551d1',
            referanseId: '60155736b44bf763f6ebf152',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13778',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13778',
              tittel: {
                nb: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak',
                nn: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak',
                en: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          }
        ]
      }
    ]
  },
  {
    name: 'Spennende Litteratur',
    username: 'spe2610',
    classId: 'TESVS:3HEA',
    level: 'VG3',
    utdanningsprogram: [
      {
        id: 'HS',
        groupId: 'HS_TESVS',
        type: 'utdanningsprogram',
        name: 'Helse- og oppvekstfag',
        description: '',
        schoolId: 'TESVS@38099',
        schoolName: 'Testen videregående skole',
        validFrom: '1971-11-18',
        validTo: '1986-11-26',
        adminPeriod: '1971/1986'
      }
    ],
    maal: [
      {
        programomraade: {
          kode: 'HSAMB3----',
          'url-data': 'https://data.udir.no/kl06/v201906/programomraader/HSAMB3----',
          tittel: {
            nb: 'Ambulansefaget',
            nn: 'Ambulansefaget',
            en: 'Ambulance Service Skills'
          },
          opplaeringssted: [
            'http://psi.udir.no/kl06/opplaeringssted_bedrift'
          ]
        },
        maal: [
          {
            _id: '6015576e442afa643265529c',
            referanseId: '60155736b44bf763f6ebf182',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13785',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13785',
              tittel: {
                nb: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak',
                nn: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak',
                en: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326552a0',
            referanseId: '60155736b44bf763f6ebf182',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13776',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13776',
              tittel: {
                nb: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende',
                nn: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende',
                en: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa643265529b',
            referanseId: '60155736b44bf763f6ebf182',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13784',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13784',
              tittel: {
                nb: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter ',
                nn: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter ',
                en: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter '
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa643265529f',
            referanseId: '60155736b44bf763f6ebf182',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13786',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13786',
              tittel: {
                nb: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak ',
                nn: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak ',
                en: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak '
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa643265529d',
            referanseId: '60155736b44bf763f6ebf182',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13782',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13782',
              tittel: {
                nb: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen',
                nn: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen',
                en: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa643265529e',
            referanseId: '60155736b44bf763f6ebf182',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13778',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13778',
              tittel: {
                nb: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak',
                nn: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak',
                en: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          }
        ]
      }
    ]
  },
  {
    name: 'Voksende Fornøyelse',
    username: 'vok0909',
    classId: 'TESVS:3HEA',
    level: 'VG3',
    utdanningsprogram: [
      {
        id: 'HS',
        groupId: 'HS_TESVS',
        type: 'utdanningsprogram',
        name: 'Helse- og oppvekstfag',
        description: '',
        schoolId: 'TESVS@38099',
        schoolName: 'Testen videregående skole',
        validFrom: '1971-11-18',
        validTo: '1986-11-26',
        adminPeriod: '1971/1986'
      }
    ],
    maal: [
      {
        programomraade: {
          kode: 'HSAMB3----',
          'url-data': 'https://data.udir.no/kl06/v201906/programomraader/HSAMB3----',
          tittel: {
            nb: 'Ambulansefaget',
            nn: 'Ambulansefaget',
            en: 'Ambulance Service Skills'
          },
          opplaeringssted: [
            'http://psi.udir.no/kl06/opplaeringssted_bedrift'
          ]
        },
        maal: [
          {
            _id: '6015576e442afa64326552dc',
            referanseId: '60155736b44bf763f6ebf18b',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13778',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13778',
              tittel: {
                nb: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak',
                nn: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak',
                en: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326552de',
            referanseId: '60155736b44bf763f6ebf18b',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13782',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13782',
              tittel: {
                nb: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen',
                nn: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen',
                en: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326552df',
            referanseId: '60155736b44bf763f6ebf18b',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13784',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13784',
              tittel: {
                nb: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter ',
                nn: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter ',
                en: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter '
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326552e0',
            referanseId: '60155736b44bf763f6ebf18b',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13786',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13786',
              tittel: {
                nb: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak ',
                nn: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak ',
                en: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak '
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326552e1',
            referanseId: '60155736b44bf763f6ebf18b',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13785',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13785',
              tittel: {
                nb: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak',
                nn: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak',
                en: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326552dd',
            referanseId: '60155736b44bf763f6ebf18b',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13776',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13776',
              tittel: {
                nb: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende',
                nn: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende',
                en: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          }
        ]
      }
    ]
  },
  {
    name: 'Usannferdig Tanke',
    username: 'usa1106',
    classId: 'TESVS:3HEA',
    level: 'VG3',
    utdanningsprogram: [
      {
        id: 'HS',
        groupId: 'HS_TESVS',
        type: 'utdanningsprogram',
        name: 'Helse- og oppvekstfag',
        description: '',
        schoolId: 'TESVS@38099',
        schoolName: 'Testen videregående skole',
        validFrom: '1971-11-18',
        validTo: '1986-11-26',
        adminPeriod: '1971/1986'
      }
    ],
    maal: [
      {
        programomraade: {
          kode: 'HSAMB3----',
          'url-data': 'https://data.udir.no/kl06/v201906/programomraader/HSAMB3----',
          tittel: {
            nb: 'Ambulansefaget',
            nn: 'Ambulansefaget',
            en: 'Ambulance Service Skills'
          },
          opplaeringssted: [
            'http://psi.udir.no/kl06/opplaeringssted_bedrift'
          ]
        },
        maal: [
          {
            _id: '6015576e442afa64326551a2',
            referanseId: '60155736b44bf763f6ebf147',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13785',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13785',
              tittel: {
                nb: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak',
                nn: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak',
                en: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326551a6',
            referanseId: '60155736b44bf763f6ebf147',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13782',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13782',
              tittel: {
                nb: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen',
                nn: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen',
                en: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326551a5',
            referanseId: '60155736b44bf763f6ebf147',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13784',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13784',
              tittel: {
                nb: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter ',
                nn: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter ',
                en: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter '
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326551a3',
            referanseId: '60155736b44bf763f6ebf147',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13778',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13778',
              tittel: {
                nb: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak',
                nn: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak',
                en: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326551a4',
            referanseId: '60155736b44bf763f6ebf147',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13786',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13786',
              tittel: {
                nb: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak ',
                nn: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak ',
                en: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak '
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326551a7',
            referanseId: '60155736b44bf763f6ebf147',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13776',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13776',
              tittel: {
                nb: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende',
                nn: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende',
                en: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          }
        ]
      }
    ]
  },
  {
    name: 'Våken Beundring',
    username: 'vak0909',
    classId: 'TESVS:3HEA',
    level: 'VG3',
    utdanningsprogram: [
      {
        id: 'HS',
        groupId: 'HS_TESVS',
        type: 'utdanningsprogram',
        name: 'Helse- og oppvekstfag',
        description: '',
        schoolId: 'TESVS@38099',
        schoolName: 'Testen videregående skole',
        validFrom: '1971-11-18',
        validTo: '1986-11-26',
        adminPeriod: '1971/1986'
      }
    ],
    maal: [
      {
        programomraade: {
          kode: 'HSAMB3----',
          'url-data': 'https://data.udir.no/kl06/v201906/programomraader/HSAMB3----',
          tittel: {
            nb: 'Ambulansefaget',
            nn: 'Ambulansefaget',
            en: 'Ambulance Service Skills'
          },
          opplaeringssted: [
            'http://psi.udir.no/kl06/opplaeringssted_bedrift'
          ]
        },
        maal: [
          {
            _id: '6015576e442afa64326552af',
            referanseId: '60155736b44bf763f6ebf185',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13786',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13786',
              tittel: {
                nb: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak ',
                nn: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak ',
                en: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak '
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326552b1',
            referanseId: '60155736b44bf763f6ebf185',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13776',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13776',
              tittel: {
                nb: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende',
                nn: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende',
                en: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326552ae',
            referanseId: '60155736b44bf763f6ebf185',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13778',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13778',
              tittel: {
                nb: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak',
                nn: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak',
                en: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326552b2',
            referanseId: '60155736b44bf763f6ebf185',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13784',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13784',
              tittel: {
                nb: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter ',
                nn: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter ',
                en: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter '
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326552ad',
            referanseId: '60155736b44bf763f6ebf185',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13782',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13782',
              tittel: {
                nb: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen',
                nn: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen',
                en: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326552b0',
            referanseId: '60155736b44bf763f6ebf185',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13785',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13785',
              tittel: {
                nb: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak',
                nn: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak',
                en: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          }
        ]
      }
    ]
  },
  {
    name: 'Krass Barberskum',
    username: 'kra2503',
    classId: 'TESVS:3HEA',
    level: 'VG3',
    utdanningsprogram: [
      {
        id: 'HS',
        groupId: 'HS_TESVS',
        type: 'utdanningsprogram',
        name: 'Helse- og oppvekstfag',
        description: '',
        schoolId: 'TESVS@38099',
        schoolName: 'Testen videregående skole',
        validFrom: '1971-11-18',
        validTo: '1986-11-26',
        adminPeriod: '1971/1986'
      }
    ],
    maal: [
      {
        programomraade: {
          kode: 'HSAMB3----',
          'url-data': 'https://data.udir.no/kl06/v201906/programomraader/HSAMB3----',
          tittel: {
            nb: 'Ambulansefaget',
            nn: 'Ambulansefaget',
            en: 'Ambulance Service Skills'
          },
          opplaeringssted: [
            'http://psi.udir.no/kl06/opplaeringssted_bedrift'
          ]
        },
        maal: [
          {
            _id: '6015576e442afa64326551c0',
            referanseId: '60155736b44bf763f6ebf14e',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13776',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13776',
              tittel: {
                nb: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende',
                nn: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende',
                en: 'kommunisere med og vise profesjonell omsorg for pasienter og pårørende'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326551c2',
            referanseId: '60155736b44bf763f6ebf14e',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13782',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13782',
              tittel: {
                nb: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen',
                nn: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen',
                en: 'forebygge smittespredning og ivareta god hygiene under ambulanseoppdrag og på ambulansestasjonen'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326551c3',
            referanseId: '60155736b44bf763f6ebf14e',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13784',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13784',
              tittel: {
                nb: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter ',
                nn: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter ',
                en: 'utføre enkel undersøkelse, utføre akutte behandlingstiltak, reevaluere og overvåke pasienter '
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326551c4',
            referanseId: '60155736b44bf763f6ebf14e',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13778',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13778',
              tittel: {
                nb: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak',
                nn: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak',
                en: 'identifisere fysiske og psykiske behov hos pasienter og utføre nødvendige pleietiltak'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326551c1',
            referanseId: '60155736b44bf763f6ebf14e',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13785',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13785',
              tittel: {
                nb: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak',
                nn: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak',
                en: 'identifisere tegn og symptomer på akutt sykdom, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak'
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          },
          {
            _id: '6015576e442afa64326551c5',
            referanseId: '60155736b44bf763f6ebf14e',
            referanseTittel: 'AMBULANSE AS',
            grep: {
              kode: 'K13786',
              'url-data': 'https://data.udir.no/kl06/v201906/kompetansemaal/K13786',
              tittel: {
                nb: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak ',
                nn: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak ',
                en: 'identifisere tegn og symptomer på akutt skade, forklare utvikling i kroppen som kan true vitale livsfunksjoner, og iverksette nødvendige tiltak '
              }
            },
            arbeidsoppgaver: 'Lede morgentrimmen'
          }
        ]
      }
    ]
  }
]

export const DOCUMENTS = require('./_documents.json')
