import React, { useState } from 'react'
import Moment from 'react-moment'
import { Paragraph, InitialsBadge, Skeleton, IconButton, StatisticsGroup, StatisticsCard } from '@vtfk/components'

import ClassCard from '../../components/class-card'
import ClassPanel from '../../components/class-panel'

import repackDocumentType from '../../lib/repack-document-type'
import { PreviewDocumentModal } from '../../components/PreviewDocumentModal'
import { YFFModal } from '../../components/YFFModal'

export function Basisgruppe ({ group, documents, conversations, notes, yff }) {
  const [previewDocument, setPreviewDocument] = useState(false)
  const [yffModalOpen, setYFFModalOpen] = useState(false)

  return (
    <>
      {
        previewDocument &&
          <PreviewDocumentModal
            open={!!previewDocument}
            previewDoc={previewDocument}
            title={previewDocument ? repackDocumentType(previewDocument.type, previewDocument.variant) : ''}
            onDismiss={() => { setPreviewDocument(false) }}
            onFinished={() => { setPreviewDocument(false) }}
          />
      }

      <YFFModal
        yff={yff}
        open={yffModalOpen}
        title={`YFF-rapport for ${group && group.name}`}
        onDismiss={() => { setYFFModalOpen(false) }}
        onFinished={() => { setYFFModalOpen(false) }}
      />

      <ClassCard group={group}>
        {
          yff && yff.length > 0 &&
            <div className='class-information-actions'>
              <IconButton
                className='class-information-action-button'
                onClick={() => setYFFModalOpen(true)}
                icon='activity'
                bordered
              >
                Åpne YFF-rapport
              </IconButton>
            </div>
        }
      </ClassCard>

      <StatisticsGroup className='stats-group'>
        {
          !documents
            ? (
              <>
                <Skeleton variant='rectangle' height='126px' width='calc(100% / 3 - (32px))' style={{ marginLeft: '32px' }} />
                <Skeleton variant='rectangle' height='126px' width='calc(100% / 3 - (32px))' style={{ marginLeft: '32px' }} />
                <Skeleton variant='rectangle' height='126px' width='calc(100% / 3 - (32px))' style={{ marginLeft: '32px' }} />
              </>
              )
            : (
              <>
                <StatisticsCard title='varselbrev' size='large'>{documents ? documents.length.toString() : '0'}</StatisticsCard>
                <StatisticsCard title='dokumenterte elevsamtaler' size='large'>{conversations ? conversations.length.toString() : '0'}</StatisticsCard>
                <StatisticsCard title='notater til elevmappa' size='large'>{notes ? notes.length.toString() : '0'}</StatisticsCard>
              </>
              )
        }
      </StatisticsGroup>

      <ClassPanel icon='activity' title='Varsler'>
        {
          !documents && Array(6).fill().map(function (i) {
            return (
              <tr key={i}>
                <td>
                  <div className='activity-name'>
                    <Skeleton variant='circle'><InitialsBadge size='small' /></Skeleton>
                    <Skeleton className='paragraph' randomWidth={[40, 80]} />
                  </div>
                </td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
              </tr>
            )
          })
        }

        {
          documents && documents.map(function (doc) {
            return (
              <tr key={doc._id} className='clickable-row' tabIndex='0' onClick={() => setPreviewDocument(doc)}>
                <td>
                  <div className='activity-name'>
                    <InitialsBadge firstName={doc.student.firstName} lastName={doc.student.lastName} size='small' />
                    <Paragraph>
                      {doc.student.name}
                    </Paragraph>
                  </div>
                </td>
                <td>
                  <Paragraph>{repackDocumentType(doc.type, doc.variant)}</Paragraph>
                </td>
                <td>
                  <Paragraph>{doc.content.classes ? doc.content.classes.length > 1 ? 'Flere fag' : doc.content.classes[0].nb : doc.content.period.nb}</Paragraph>
                </td>
                <td>
                  <Paragraph><Moment locale='nb' format='DD. MMM YYYY'>{doc.created.timestamp}</Moment></Paragraph>
                </td>
              </tr>
            )
          })
        }
        {
          documents && documents.length === 0 &&
            <tr>
              <td style={{ textAlign: 'left' }}>
                <Paragraph>Ingen varsler registrert for noen elever i denne klassen.</Paragraph>
              </td>
            </tr>
        }
      </ClassPanel>

      <ClassPanel icon='activity' title='Samtaler'>
        {
          !conversations &&
            Array(6).fill().map(function (i) {
              return (
                <tr key={i}>
                  <td>
                    <div className='activity-name'>
                      <Skeleton variant='circle'><InitialsBadge size='small' /></Skeleton>
                      <Skeleton className='paragraph' randomWidth={[40, 80]} />
                    </div>
                  </td>
                  <td><Skeleton /></td>
                  <td><Skeleton /></td>
                </tr>
              )
            })
        }
        {
          conversations && conversations.map(function (doc) {
            return (
              <tr key={doc._id} className='clickable-row' tabIndex='0' onClick={() => setPreviewDocument(doc)}>
                <td>
                  <div className='activity-name'>
                    <InitialsBadge firstName={doc.student.firstName} lastName={doc.student.lastName} size='small' />
                    <Paragraph>
                      {doc.student.name}
                    </Paragraph>
                  </div>
                </td>
                <td>
                  <Paragraph>{doc.teacher.name}</Paragraph>
                </td>
                <td>
                  <Paragraph><Moment locale='nb' format='DD. MMM YYYY'>{doc.created.timestamp}</Moment></Paragraph>
                </td>
              </tr>
            )
          })
        }
        {
          conversations && conversations.length === 0 &&
            <tr>
              <td style={{ textAlign: 'left' }}>
                <Paragraph>Denne klassen har ingen registrerte elevsamtaler.</Paragraph>
              </td>
            </tr>
        }
      </ClassPanel>

      <ClassPanel icon='activity' title='Notater'>
        {
          !notes &&
            Array(6).fill().map(function (i) {
              return (
                <tr key={i}>
                  <td>
                    <div className='activity-name'>
                      <Skeleton variant='circle'><InitialsBadge size='small' /></Skeleton>
                      <Skeleton className='paragraph' randomWidth={[40, 80]} />
                    </div>
                  </td>
                  <td><Skeleton /></td>
                  <td><Skeleton /></td>
                </tr>
              )
            })
        }
        {
          notes && notes.map(function (doc) {
            return (
              <tr key={doc._id} className='clickable-row' tabIndex='0' onClick={() => setPreviewDocument(doc)}>
                <td>
                  <div className='activity-name'>
                    <InitialsBadge firstName={doc.student.firstName} lastName={doc.student.lastName} size='small' />
                    <Paragraph>
                      {doc.student.name}
                    </Paragraph>
                  </div>
                </td>
                <td>
                  <Paragraph>{doc.teacher.name}</Paragraph>
                </td>
                <td>
                  <Paragraph><Moment locale='nb' format='DD. MMM YYYY'>{doc.created.timestamp}</Moment></Paragraph>
                </td>
              </tr>
            )
          })
        }
        {
          notes && notes.length === 0 &&
            <tr>
              <td style={{ textAlign: 'left' }}>
                <Paragraph>Ingen av elevene i klassen har noen notater registrert på seg.</Paragraph>
              </td>
            </tr>
        }
      </ClassPanel>
    </>
  )
}
