import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Layout } from '../../layout'
import { useSession } from '@vtfk/react-msal'
import { Link, Paragraph, Icon } from '@vtfk/components'

import useEffectOnce from '../../components/useEffectOnce'

import ClassCard from '../../components/class-card'
import { Basisgruppe } from './basisgruppe'

import { APP, ROUTES } from '../../config'
import './styles.scss'

export function Class () {
  const { schoolId, id } = useParams()
  const [schoolClass, setSchoolClass] = useState(null)
  const [error, setError] = useState(null)
  const [documents, setDocuments] = useState(null)
  const [conversations, setConversations] = useState([])
  const [notes, setNotes] = useState([])
  const [yff, setYff] = useState([])
  const { apiGet } = useSession()

  async function getClass () {
    const group = await apiGet(APP.API_URL + '/classes/' + encodeURIComponent(id))
    if (!group || group.error) setError(true)
    if (group.data) setSchoolClass(group.data)
  }

  async function getDocuments () {
    const docs = await apiGet(APP.API_URL + '/classes/' + encodeURIComponent(id) + '/documents')

    if (docs && docs.data) {
      const docsOrderedByModified = docs.data.sort((a, b) => (a.created.timestamp < b.created.timestamp) ? 1 : -1)
      const docsVarsler = docsOrderedByModified.filter((item) => item.type === 'varsel')
      const docsConversations = docsOrderedByModified.filter((item) => item.type === 'samtale')
      const docsNotes = docsOrderedByModified.filter((item) => item.type === 'notat')

      setDocuments(docsVarsler)
      setConversations(docsConversations)
      setNotes(docsNotes)
    }
  }

  async function getYFF () {
    const groupYFF = await apiGet(APP.API_URL + '/classes/' + encodeURIComponent(id) + '/yff')
    if (groupYFF.data) setYff(groupYFF.data)
  }

  useEffectOnce(() => {
    getClass()
    getDocuments()
    getYFF()
  })

  return (
    <Layout>
      <div className='class'>

        <Link className='back-link' href={`/${ROUTES.SCHOOLS}/${schoolId}`} noStyle leftIcon={<Icon name='arrowLeft' size='xsmall' />}>Tilbake til skolen</Link>

        {
          // Basisgrupper
          (!error &&
            <Basisgruppe group={schoolClass} documents={documents} conversations={conversations} notes={notes} yff={yff.length === 0 ? false : yff} />) ||

          // Ukjent gruppetype
          (schoolClass && schoolClass.type &&
            <>😟 Klarte ikke å vise gruppa.. Kontakt systemansvarlig! {schoolClass.type}</>) ||

          // HTTP error / ingen tilgang
          (error &&
            <>
              <ClassCard group={{ name: decodeURIComponent(id) }} />
              <Paragraph className='error-message'>
                Du har ikke tilgang til denne klassen!<br />
                Dersom du mener dette er feil, ta kontakt med Visma InSchool ansvarlig på skolen din.
              </Paragraph>
            </>)

          // TODO: Add loading/skeleton view?
        }
      </div>
    </Layout>
  )
}
