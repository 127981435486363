import { rest } from 'msw'
import { APP } from '../config'
import { CLASSES, SCHOOLS, DOCUMENTS, YFF } from './mock-data'

export const generateResponseObject = (response) => {
  return {
    data: response,
    count: response.length || undefined
  }
}

export const generateErrorObject = (statusCode, message, innerError) => {
  return {
    error: {
      statusCode: statusCode || 500,
      message: message || 'Unexpected error occured!',
      innerError
    }
  }
}

const decode = id => decodeURIComponent(decodeURIComponent(id))

const SCHOOL_ERROR = generateErrorObject(403, "You don't have access to this school!")
const CLASS_ERROR = generateErrorObject(403, "You don't have access to this class!")

export const handlers = [
  rest.get(`${APP.API_URL}/schools`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(generateResponseObject(SCHOOLS))
    )
  }),
  rest.get(`${APP.API_URL}/schools/:id`, (req, res, ctx) => {
    const { id } = req.params
    const school = SCHOOLS.find(school => school.shortName === id || school.schoolId === id)

    return res(
      ctx.status(school ? 200 : 403),
      ctx.json(school ? generateResponseObject(school) : SCHOOL_ERROR)
    )
  }),
  rest.get(`${APP.API_URL}/schools/:id/classes`, (req, res, ctx) => {
    const { id } = req.params
    const school = SCHOOLS.find(school => school.shortName === id || school.schoolId === id)

    return res(
      ctx.status(school ? 200 : 403),
      ctx.json(school ? generateResponseObject(CLASSES.map(group => group.groupId)) : SCHOOL_ERROR)
    )
  }),
  rest.get(`${APP.API_URL}/schools/:id/documents`, (req, res, ctx) => {
    const { id } = req.params
    const school = SCHOOLS.find(school => school.shortName === id || school.schoolId === id)
    const docs = DOCUMENTS.filter(doc => doc.school.id === school.shortName)
    return res(
      ctx.status(school ? 200 : 403),
      ctx.json(school ? generateResponseObject(docs) : SCHOOL_ERROR)
    )
  }),
  rest.get(`${APP.API_URL}/classes/:id`, (req, res, ctx) => {
    const { id } = req.params
    const group = CLASSES.find(group => group.groupId === decode(id))

    return res(
      ctx.status(group ? 200 : 403),
      ctx.json(group ? generateResponseObject(group) : CLASS_ERROR)
    )
  }),
  rest.get(`${APP.API_URL}/classes/:id/documents`, (req, res, ctx) => {
    const { id } = req.params
    const group = CLASSES.find(group => group.groupId === decode(id))
    const docs = DOCUMENTS.filter(doc => doc.student.classId === group.groupId)

    return res(
      ctx.status(group ? 200 : 403),
      ctx.json(group ? generateResponseObject(docs) : CLASS_ERROR)
    )
  }),
  rest.get(`${APP.API_URL}/classes/:id/yff`, (req, res, ctx) => {
    const { id } = req.params
    const group = CLASSES.find(group => group.groupId === decode(id))
    const yff = YFF.filter(stud => stud.classId === group.groupId)

    return res(
      ctx.status(200),
      ctx.json(generateResponseObject(yff))
    )
  }),
  rest.get(`${APP.API_URL}/stats`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ count: DOCUMENTS.count })
    )
  }),
  rest.get(`${APP.API_URL}/stats/school`, (req, res, ctx) => {
    const types = [...new Set(DOCUMENTS.map(doc => doc.type))]
      .map(type => ({
        type,
        schools: SCHOOLS.map(school => ({
          id: school.shortName,
          name: school.officialName,
          count: DOCUMENTS.filter(doc => doc.type === type && doc.school.id === school.shortName).length
        })),
        count: DOCUMENTS.filter(doc => doc.type === type).length
      }))

    return res(
      ctx.status(200),
      ctx.json(types)
    )
  })
]
