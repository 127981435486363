import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton, Paragraph, Link, Icon, ErrorMessage, StatisticsGroup, StatisticsCard } from '@vtfk/components'
import { useSession } from '@vtfk/react-msal'
import useEffectOnce from '../../components/useEffectOnce'
import { Layout } from '../../layout'
import { TypeListModal } from '../../components/TypeListModal'
import { SchoolCard } from '../../components/SchoolCard'
import { ROUTES, APP } from '../../config'

import './styles.scss'

export function School () {
  const { id } = useParams()
  const { apiGet } = useSession()

  const [school, setSchool] = useState(null)
  const [schoolClasses, setSchoolClasses] = useState(null)
  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState([])
  const [conversations, setConversations] = useState([])
  const [notes, setNotes] = useState([])
  const [error, setError] = useState(false)

  const [typeModalOpen, setTypeModalOpen] = useState(false)

  async function getSchool () {
    const res = await apiGet(APP.API_URL + '/schools/' + id)
    if (!res || res.error) setError(true)
    if (res.data) setSchool(res.data)
  }

  async function getSchoolClasses () {
    const groups = await apiGet(APP.API_URL + '/schools/' + id + '/classes')
    if (!groups || groups.error) setError(true)
    if (groups.data) setSchoolClasses(groups.data)
  }

  async function getSchoolDocuments () {
    const docs = await apiGet(APP.API_URL + '/schools/' + id + '/documents')

    if (docs && docs.data) {
      const docsOrderedByModified = docs.data.sort((a, b) => a.student.classId === b.student.classId ? (a.created.timestamp < b.created.timestamp) : a.student.classId.localeCompare(b.student.classId))
      const docsVarsler = docsOrderedByModified.filter((item) => item.type === 'varsel')
      const docsConversations = docsOrderedByModified.filter((item) => item.type === 'samtale')
      const docsNotes = docsOrderedByModified.filter((item) => item.type === 'notat')

      setDocuments(docsVarsler)
      setConversations(docsConversations)
      setNotes(docsNotes)
      setLoading(false)
    }
  }

  useEffectOnce(() => {
    setLoading(true)
    getSchool()
    getSchoolClasses()
    getSchoolDocuments()
  })

  return (
    <Layout>
      <div className='school'>
        <TypeListModal
          open={!!typeModalOpen}
          documents={typeModalOpen === 'varsel' ? documents : typeModalOpen === 'samtale' ? conversations : notes}
          title={typeModalOpen === 'varsel' ? 'Alle varselbrev' : typeModalOpen === 'samtale' ? 'Alle elevsamtaler' : 'Alle notater'}
          onDismiss={() => { setTypeModalOpen(false) }}
          onFinished={() => { setTypeModalOpen(false) }}
        />

        <Link className='back-link' href={`/${ROUTES.SCHOOLS}`} noStyle leftIcon={<Icon name='arrowLeft' size='xsmall' />}>Til skoleoversikten</Link>
        {
          school &&
            <>
              <SchoolCard school={school} />

              <StatisticsGroup className='stats-group'>
                {
                  !loading && documents
                    ? <StatisticsCard title='varselbrev' size='large' onClick={() => setTypeModalOpen('varsel')}>{documents ? documents.length : '0'}</StatisticsCard>
                    : <Skeleton variant='rectangle' height='154px' width='calc(100% / 3 - (32px))' style={{ marginLeft: '32px' }} />
                }
                {
                  !loading && conversations
                    ? <StatisticsCard title='dokumenterte elevsamtaler' size='large' onClick={() => setTypeModalOpen('samtale')}>{conversations ? conversations.length : '0'}</StatisticsCard>
                    : <Skeleton variant='rectangle' height='154px' width='calc(100% / 3 - (32px))' style={{ marginLeft: '32px' }} />
                }
                {
                  !loading && notes
                    ? <StatisticsCard title='notater til elevmappa' size='large' onClick={() => setTypeModalOpen('notat')}>{notes ? notes.length : '0'}</StatisticsCard>
                    : <Skeleton variant='rectangle' height='154px' width='calc(100% / 3 - (32px))' style={{ marginLeft: '32px' }} />
                }
              </StatisticsGroup>

              {
                error &&
                  <ErrorMessage>Du har ikke tilgang til denne skolen!</ErrorMessage>
              }
              {
                !error &&
                  <table className='data-actions-table'>
                    <thead>
                      <tr>
                        <th><Paragraph size='small'>Klasse</Paragraph></th>
                        <th><Paragraph size='small'>Trinn</Paragraph></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        !schoolClasses && Array(20).fill().map((i) => (
                          <tr key={i}>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                          </tr>
                        ))
                      }
                      {
                        schoolClasses && schoolClasses.map(group => ({
                          id: group,
                          name: group.includes(':') ? group.split(':')[1] : group,
                          level: parseInt(/:\d/.test(group) ? /:(\d)/.exec(group)[1] : '1')
                        }))
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map(group => (
                            <tr key={group.id}>
                              <td>
                                <Paragraph>
                                  <Link href={`/${ROUTES.SCHOOLS}/${id}/${encodeURIComponent(group.id)}`}>{group.name}</Link>
                                </Paragraph>
                              </td>
                              <td>
                                <Paragraph>
                                  VG{group.level}
                                </Paragraph>
                              </td>

                            </tr>
                          ))
                      }
                    </tbody>
                  </table>
              }
              <div className='school-footer-alert'>
                <strong>Finner du ikke klassen du er på jakt etter?</strong> Lista inneholder kun klasser det er produsert et dokument til i MinElev.
              </div>
            </>
        }
        {
          !school && error &&
            <ErrorMessage>Du har ikke tilgang til denne skolen!</ErrorMessage>
        }
      </div>
    </Layout>
  )
}
