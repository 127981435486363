import React from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'

import useEffectOnce from '../../components/useEffectOnce'

import { Button, Heading2, Heading3, Modal, ModalBody, Paragraph } from '@vtfk/components'

import ErrorFallback from '../ErrorFallback'

import './styles.scss'

export function YFFModal ({ yff, ...props }) {
  useEffectOnce(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') props.onDismiss()
    }

    document.addEventListener('keyup', handleKeyPress)
    return () => document.removeEventListener('keyup', handleKeyPress)
  })

  const classCodes = {
    VG1: {
      header: 'YFF4106 Yrkesfaglig fordypning Vg1',
      hours: 168
    },
    VG2: {
      header: 'YFF4209 Yrkesfaglig fordypning Vg2',
      hours: 253
    }
  }

  const getClassCode = student => {
    if (student.level === 'VG1') return classCodes.VG1
    if (student.level === 'VG2') return classCodes.VG2
    if (student.maal && student.maal.find(mal => mal.programomraade && mal.programomraade.kode[5] === '1')) return classCodes.VG1
    return classCodes.VG2
  }

  const copyReportToClipboard = username => {
    const report = document.getElementById(`yff-report-${username}`)
    const btn = document.getElementById(`yff-copy-${username}`)

    const range = document.createRange()
    range.selectNode(report)
    window.getSelection().removeAllRanges() // clear current selection
    window.getSelection().addRange(range) // select text
    document.execCommand('copy') // copy text
    window.getSelection().removeAllRanges() // clear selection

    btn.children[0].innerText = 'Kompetansebevis kopiert til utklippstavlen!'
    btn.classList.remove('button-secondary')
    btn.classList.add('button-secondary2')
  }

  return (
    <Sentry.ErrorBoundary
      fallback={ErrorFallback}
      onReset={props.onDismiss}
    >

      <Modal
        {...props}
        className='yff-modal'
        onDismiss={props.onDismiss}
        onFinished={props.onFinished}
      >
        <ModalBody>
          {
            yff && yff.map(student => {
              const classCode = getClassCode(student)
              const utdprog = student.utdanningsprogram[0] || {}
              return (
                <div className='yff-modal-report' key={student.username}>
                  <Heading2>{student.name}</Heading2>
                  <div id={`yff-report-${student.username}`}>
                    <Paragraph>
                      {classCode.header}<br />
                      Årstimer: {classCode.hours}<br />
                      {utdprog ? `Utdanningsprogram: ${utdprog.name}` : ''}
                    </Paragraph>
                    <Paragraph>
                      Kompetansemålene bygger på:
                    </Paragraph>

                    {
                      student.maal.map(({ programomraade, maal }) => (
                        <div key={`${student.username}-${programomraade.kode}`}>
                          <Heading3>Programområde: {programomraade.tittel.nb}</Heading3>
                          <Paragraph>Kompetansemål for opplæringen:</Paragraph>

                          <ul>
                            {
                              maal && maal.map(mal => (
                                <li key={`${student.username}-${programomraade.kode}-${mal.grep.kode}`}>
                                  {mal.grep.tittel.nb}
                                </li>
                              ))
                            }
                          </ul>
                        </div>
                      ))
                    }
                  </div>

                  <Button id={`yff-copy-${student.username}`} type='secondary' size='small' onClick={() => copyReportToClipboard(student.username)}>
                    Trykk for å kopiere kompetansebeviset
                  </Button>

                  <hr />
                </div>
              )
            })
          }
        </ModalBody>
      </Modal>
    </Sentry.ErrorBoundary>
  )
}

YFFModal.propTypes = {
  yff: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onDismiss: PropTypes.func.isRequired,
  onFinished: PropTypes.func.isRequired,
  className: PropTypes.string
}
