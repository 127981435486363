import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Moment from 'react-moment'
import * as Sentry from '@sentry/react'
import { Modal, ModalBody, Paragraph, InitialsBadge } from '@vtfk/components'

import useEffectOnce from '../useEffectOnce'

import ErrorFallback from '../ErrorFallback'
import repackDocumentType from '../../lib/repack-document-type'

import './styles.scss'
import { PreviewDocumentModal } from '../PreviewDocumentModal'

export function TypeListModal ({ documents, ...props }) {
  const [previewDocument, setPreviewDocument] = useState(false)

  // Create copy of previewModalState in ref, so we can access it from our escape key listener
  const previewModalStateRef = useRef(previewDocument)
  useEffect(() => { previewModalStateRef.current = !!previewDocument }, [previewDocument])

  useEffectOnce(() => {
    // Close modal on escape
    const handleKeyPress = (event) => {
      if (event.key === 'Escape' && !previewModalStateRef.current) props.onDismiss()
    }

    document.addEventListener('keyup', handleKeyPress)
    return () => document.removeEventListener('keyup', handleKeyPress)
  })

  return (
    <Sentry.ErrorBoundary
      fallback={ErrorFallback}
      onReset={props.onDismiss}
    >

      <Modal
        {...props}
        className='type-list-modal'
        onDismiss={props.onDismiss}
        onFinished={props.onFinished}
      >
        <ModalBody>

          {
            previewDocument &&
              <PreviewDocumentModal
                open={!!previewDocument}
                previewDoc={previewDocument}
                title={previewDocument ? repackDocumentType(previewDocument.type, previewDocument.variant) : ''}
                onDismiss={() => { setPreviewDocument(false) }}
                onFinished={() => { setPreviewDocument(false) }}
              />
          }

          <div className='modal-table'>
            <table className='data-actions-table'>
              <thead>
                <tr>
                  <th><Paragraph size='small'>Elev</Paragraph></th>
                  <th><Paragraph size='small'>Klasse</Paragraph></th>
                  <th><Paragraph size='small'>Type</Paragraph></th>
                  <th><Paragraph size='small'>Dato</Paragraph></th>
                  <th><Paragraph size='small'>Sendt av</Paragraph></th>
                </tr>
              </thead>
              <tbody>
                {
                  documents && documents.map(function (doc) {
                    return (
                      <tr key={doc._id} className='clickable-row' tabIndex='0' onClick={() => setPreviewDocument(doc)}>
                        <td>
                          <div className='name'>
                            <InitialsBadge firstName={doc.student.firstName} lastName={doc.student.lastName} size='small' />
                            <Paragraph>{doc.student.name}</Paragraph>
                          </div>
                        </td>
                        <td>
                          <Paragraph>{doc.student.classId}</Paragraph>
                        </td>
                        <td>
                          <Paragraph>{repackDocumentType(doc.type, doc.variant)}</Paragraph>
                        </td>
                        <td>
                          <Paragraph><Moment locale='nb' format='DD. MMM YYYY'>{doc.created.timestamp}</Moment></Paragraph>
                        </td>
                        <td>
                          <Paragraph>{doc.teacher.name}</Paragraph>
                        </td>
                      </tr>
                    )
                  })
                }
                {
                  documents && documents.length === 0 &&
                    <tr>
                      <td style={{ textAlign: 'left' }}>
                        <Paragraph>Ingen varsler registrert for noen elever i denne klassen.</Paragraph>
                      </td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </ModalBody>
      </Modal>
    </Sentry.ErrorBoundary>
  )
}

TypeListModal.propTypes = {
  documents: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onDismiss: PropTypes.func.isRequired,
  onFinished: PropTypes.func.isRequired,
  className: PropTypes.string
}
