import React, { useState } from 'react'
import { Heading2, Paragraph, Link, ErrorMessage, Skeleton } from '@vtfk/components'
import { useSession } from '@vtfk/react-msal'
import useEffectOnce from '../../components/useEffectOnce'
import { Layout } from '../../layout'
import { ROUTES, APP } from '../../config'

import './styles.scss'

export function Schools ({ title }) {
  const [schools, setSchools] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { apiGet } = useSession()

  async function getSchools () {
    const c = await apiGet(APP.API_URL + '/schools')
    setLoading(false)

    if (c === false) setError('Det skjedde noe galt under innhenting av skolene dine...')
    if (c && c.data) setSchools(c.data)
  }

  useEffectOnce(() => {
    getSchools()
  })

  return (
    <Layout>
      <div className='schools'>

        <Heading2 className='page-title'>{title || 'Skoler'}</Heading2>

        {
          error &&
            <ErrorMessage>{error}</ErrorMessage>
        }
        {
          !error &&
          (loading || (schools && schools.length > 0)) &&
            <table className='data-actions-table'>
              <thead>
                <tr>
                  <th><Paragraph size='small'>Skole</Paragraph></th>
                  <th><Paragraph size='small'>Adresse</Paragraph></th>
                  <th><Paragraph size='small'>Telefon</Paragraph></th>
                </tr>
              </thead>
              <tbody>
                {
                  loading &&
                Array(4).fill().map(function (i) {
                  return (
                    <tr key={i}>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                    </tr>
                  )
                })
                }
                {
                  !loading &&
                  schools.map(function (school, index) {
                    return (
                      <tr key={school.organizationNumber}>
                        <td>
                          <Paragraph>
                            <Link href={`/${ROUTES.SCHOOLS}/${encodeURIComponent(school.shortName)}`}>{school.officialName}</Link>
                          </Paragraph>
                        </td>
                        <td>
                          <Paragraph>{school.address.street}, {school.address.zip} {school.address.place}</Paragraph>
                        </td>
                        <td>
                          <Paragraph>{school.phoneNumber}</Paragraph>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
        }
        {
          !loading &&
          !error &&
          schools &&
          schools.length === 0 &&
            <Paragraph>
              Du har ikke tilgang til noen skoler. Ta kontakt med systemansvarlig for å få tilgang til MinElev leder, eller sjekk ut <Link href={`/${ROUTES.HELP}`}>hjelpesidene</Link>.
            </Paragraph>
        }
      </div>
    </Layout>
  )
}
