import { useSession } from '@vtfk/react-msal'
import { Schools } from '../Schools'

import './styles.scss'

export const Home = () => {
  const { user } = useSession()

  return (<Schools title={`Hei ${user.givenName} og velkommen til MinElev leder`} />)
}
